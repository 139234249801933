<div style="padding: 1rem; position: relative;overflow: hidden;" fxLayoutAlign="center center">
    <!-- <div style="position: relative;overflow: hidden;background-image: url(toolbarBg.jpg);background-repeat: no-repeat;"
    fxLayoutAlign="center center"> -->
    <!-- EMOJIS -->
    <div *ngIf="isEmojiTime" class="emoji-box mat-elevation-z8" cdkDrag>
        <div class="emoji-handle" cdkDragHandle>
            <!-- <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
              <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg> -->
        </div>
        <div class="col">
            <div class="row cancel-emoji">
                <mat-icon (click)="emojiToggle()">cancel</mat-icon>
            </div>
            <div class="row ">
                <mat-tab-group class="tabs-emojis">
                    <mat-tab label="Emoji" style="overflow-y: scroll;">
                        <br>
                        Faces<br>
                        😀 😃 😄 😁 😆 😅 😂 🤣 😊 😇 🙂 🙃 😉 😌 😍 🥰 😘 😗 😙 😚 😋 😛 😝 😜 🤪 🤨 🧐
                        🤓 😎 🤩 🥳 😏 😒 😞 😔 😟 😕 🙁 ☹️ 😣 😖 😫 😩 🥺 😢 😭 😤 😠 😡 🤬 🤯 😳 🥵 🥶 😱 😨 😰 😥 😓
                        🤗 🤔 🤭
                        🤫 🤥 😶 😐 😑 😬 🙄 😯 😦 😧 😮 😲 🥱 😴 🤤 😪 😵 🤐 🥴 🤢 🤮 🤧 😷 🤒 🤕 🤑 🤠 😈 👿 👹 👺 🤡
                        💩 👻 💀
                        ☠️ 👽 👾 🤖 🎃 😺 😸 😹 😻 😼 😽 🙀 😿 😾
                        ❤️ 🧡 💛 💚 💙 💜 🖤 🤍 🤎 💔 ❣️ 💕 💞 💓 💗 💖 💘 💝 💟
                        <br>
                        Gestures and Body Parts<br>
                        👋 🤚 🖐 ✋ 🖖 👌 🤏 ✌️ 🤞 🤟 🤘 🤙 👈 👉 👆 🖕 👇 ☝️ 👍 👎 ✊ 👊 🤛 🤜 👏 🙌 👐 🤲 🤝 🙏 ✍️ 💅 🤳
                        💪 🦾
                        🦵 🦿 🦶 👂 🦻 👃 🧠 🦷 🦴 👀 👁 👅 👄 💋 🩸
                        <br>
                        People and Fantasy<br>
                        👶 🧒 👦 👧 🧑 👱 👨 🧔 👨‍🦰 👨‍🦱 👨‍🦳 👨‍🦲 👩 👩‍🦰 🧑‍🦰 👩‍🦱 🧑‍🦱 👩‍🦳 🧑‍🦳 👩‍🦲
                        🧑‍🦲 👱‍♀️
                        👱‍♂️ 🧓 👴 👵 🙍 🙍‍♂️ 🙍‍♀️ 🙎 🙎‍♂️ 🙎‍♀️ 🙅 🙅‍♂️ 🙅‍♀️ 🙆 🙆‍♂️ 🙆‍♀️ 💁 💁‍♂️ 💁‍♀️ 🙋
                        🙋‍♂️ 🙋‍♀️
                        🧏 🧏‍♂️ 🧏‍♀️ 🙇 🙇‍♂️ 🙇‍♀️ 🤦 🤦‍♂️ 🤦‍♀️ 🤷 🤷‍♂️ 🤷‍♀️ 🧑‍⚕️ 👨‍⚕️ 👩‍⚕️ 🧑‍🎓 👨‍🎓 👩‍🎓
                        🧑‍🏫
                        👨‍🏫 👩‍🏫 🧑‍⚖️ 👨‍⚖️ 👩‍⚖️ 🧑‍🌾 👨‍🌾 👩‍🌾 🧑‍🍳 👨‍🍳 👩‍🍳 🧑‍🔧 👨‍🔧 👩‍🔧 🧑‍🏭 👨‍🏭
                        👩‍🏭
                        🧑‍💼 👨‍💼 👩‍💼 🧑‍🔬 👨‍🔬 👩‍🔬 🧑‍💻 👨‍💻 👩‍💻 🧑‍🎤 👨‍🎤 👩‍🎤 🧑‍🎨 👨‍🎨 👩‍🎨 🧑‍✈️
                        👨‍✈️
                        👩‍✈️ 🧑‍🚀 👨‍🚀 👩‍🚀 🧑‍🚒 👨‍🚒 👩‍🚒 👮 👮‍♂️ 👮‍♀️ 🕵 🕵️‍♂️ 🕵️‍♀️ 💂 💂‍♂️ 💂‍♀️ 👷
                        👷‍♂️ 👷‍♀️
                        🤴 👸 👳 👳‍♂️ 👳‍♀️ 👲 🧕 🤵 👰 🤰 🤱 👼 🎅 🤶 🦸 🦸‍♂️ 🦸‍♀️ 🦹 🦹‍♂️ 🦹‍♀️ 🧙 🧙‍♂️ 🧙‍♀️ 🧚
                        🧚‍♂️
                        🧚‍♀️ 🧛 🧛‍♂️ 🧛‍♀️ 🧜 🧜‍♂️ 🧜‍♀️ 🧝 🧝‍♂️ 🧝‍♀️ 🧞 🧞‍♂️ 🧞‍♀️ 🧟 🧟‍♂️ 🧟‍♀️ 💆 💆‍♂️ 💆‍♀️
                        💇 💇‍♂️
                        💇‍♀️ 🚶 🚶‍♂️ 🚶‍♀️ 🧍 🧍‍♂️ 🧍‍♀️ 🧎 🧎‍♂️ 🧎‍♀️ 🧑‍🦯 👨‍🦯 👩‍🦯 🧑‍🦼 👨‍🦼 👩‍🦼 🧑‍🦽
                        👨‍🦽 👩‍🦽
                        🏃 🏃‍♂️ 🏃‍♀️ 💃 🕺 🕴 👯 👯‍♂️ 👯‍♀️ 🧖 🧖‍♂️ 🧖‍♀️ 🧘 🧑‍🤝‍🧑 👭 👫 👬 💏 👨‍❤️‍💋‍👨
                        👩‍❤️‍💋‍👩 💑
                        👨‍❤️‍👨 👩‍❤️‍👩 👪 👨‍👩‍👦 👨‍👩‍👧 👨‍👩‍👧‍👦 👨‍👩‍👦‍👦 👨‍👩‍👧‍👧 👨‍👨‍👦 👨‍👨‍👧
                        👨‍👨‍👧‍👦
                        👨‍👨‍👦‍👦 👨‍👨‍👧‍👧 👩‍👩‍👦 👩‍👩‍👧 👩‍👩‍👧‍👦 👩‍👩‍👦‍👦 👩‍👩‍👧‍👧 👨‍👦 👨‍👦‍👦
                        👨‍👧
                        👨‍👧‍👦 👨‍👧‍👧 👩‍👦 👩‍👦‍👦 👩‍👧 👩‍👧‍👦 👩‍👧‍👧 🗣 👤 👥 👣
                    </mat-tab>
                    <mat-tab label="Nature"> 🐶 🐱 🐭 🐹 🐰 🦊 🐻 🐼 🐨 🐯 🦁 🐮 🐷 🐽 🐸 🐵 🙈 🙉 🙊 🐒 🐔 🐧 🐦 🐤 🐣
                        🐥 🦆 🦅
                        🦉
                        🦇 🐺 🐗 🐴 🦄 🐝 🐛 🦋
                        🐌 🐞 🐜 🦟 🦗 🕷 🕸 🦂 🐢 🐍 🦎 🦖 🦕 🐙 🦑 🦐 🦞 🦀 🐡 🐠 🐟 🐬 🐳 🐋 🦈 🐊 🐅 🐆 🦓 🦍 🦧 🐘
                        🦛 🦏 🐪
                        🐫
                        🦒
                        🦘 🐃 🐂 🐄 🐎 🐖 🐏 🐑 🦙 🐐 🦌 🐕 🐩 🦮 🐕‍🦺 🐈 🐓 🦃 🦚 🦜 🦢 🦩 🕊 🐇 🦝 🦨 🦡 🦦 🦥 🐁 🐀
                        🐿 🦔 🐾
                        🐉
                        🐲
                        🌵 🎄 🌲 🌳 🌴 🌱 🌿 ☘️ 🍀 🎍 🎋 🍃 🍂 🍁 🍄 🐚 🌾 💐 🌷 🌹 🥀 🌺 🌸 🌼 🌻 🌞 🌝 🌛 🌜 🌚 🌕 🌖
                        🌗 🌘 🌑
                        🌒
                        🌓
                        🌔 🌙 🌎 🌍 🌏 🪐 💫 ⭐️ 🌟 ✨ ⚡️ ☄️ 💥 🔥 🌪 🌈 ☀️ 🌤 ⛅️ 🌥 ☁️ 🌦 🌧 ⛈ 🌩 🌨 ❄️ ☃️ ⛄️ 🌬 💨 💧 💦
                        ☔️ ☂️
                        🌊 🌫
                        ⌚️ 📱 📲 💻 ⌨️ 🖥 🖨 🖱 🖲 🕹 🗜 💽 💾 💿 📀 📼 📷 📸 📹 🎥 📽 🎞 📞 ☎️ 📟 📠 📺 📻 🎙 🎚 🎛 🧭
                        ⏱ ⏲ ⏰ 🕰 ⌛️ ⏳ 📡 🔋 🔌 💡 🔦 🕯 🪔 🧯 🛢 💸 💵 💴 💶 💷 💰 💳 💎 ⚖️ 🧰 🔧 🔨 ⚒ 🛠 ⛏ 🔩 ⚙️ 🧱 ⛓
                        🧲 🔫 💣 🧨 🪓 🔪 🗡 ⚔️ 🛡 🚬 ⚰️ ⚱️ 🏺 🔮 📿 🧿 💈 ⚗️ 🔭 🔬 🕳 🩹 🩺 💊 💉 🩸 🧬 🦠 🧫 🧪 🌡 🧹
                        🧺 🧻 🚽 🚰 🚿 🛁 🛀 🧼 🪒 🧽 🧴 🛎 🔑 🗝 🚪 🪑 🛋 🛏 🛌 🧸 🖼 🛍 🛒 🎁 🎈 🎏 🎀 🎊 🎉 🎎 🏮 🎐
                        🧧 ✉️ 📩 📨 📧 💌 📥 📤 📦 🏷 📪 📫 📬 📭 📮 📯 📜 📃 📄 📑 🧾 📊 📈 📉 🗒 🗓 📆 📅 🗑 📇 🗃 🗳
                        🗄 📋 📁 📂 🗂 🗞 📰 📓 📔 📒 📕 📗 📘 📙 📚 📖 🔖 🧷 🔗 📎 🖇 📐 📏 🧮 📌 📍 ✂️ 🖊 🖋 ✒️ 🖌 🖍
                        📝 ✏️ 🔍 🔎 🔏 🔐 🔒 🔓
                    </mat-tab>
                    <mat-tab label="Cool">
                        [̲̅ə̲̅٨̲̅٥̲̅٦̲̅] ✯ℋᵅᵖᵖᵞ ℬⁱʳᵗᑋᵈᵃᵞ✯ ⋆｡˚ᎶᎾᎾⅅ ℕᏐᎶℍᎢ⋆｡˚✩ 💪👦👉 ◨██══════██◧ ✌♫♪˙❤‿❤˙♫♪✌
                        ☆❋──❁ɢ∞פ ʍօ®ɴɪɴɢ❃──❋[̲̅$̲̅(̲̅5̲̅)̲̅$̲̅] ┌∩┐(ಠ_ಠ)┌∩┐ ———–[]=¤ԅ༼ ･ 〜 ･ ༽╯
                        ✌（◕ω◕）✌ 乂❤‿❤乂 ⎧ᴿᴵᴾ⎫◟◟◟◟◟◟◟ ❀◟(ó ̯ ò, ) ლ(´ ❥ `ლ) 🐕≡≡≡===💨 ヽ(ﾟεﾟヽ)))))
                        ʕっ•ᴥ•ʔ ♥️♥️🖤🖤☠️💀☠️🖤🖤♥️♥️ ★ PLΔyBӨY ★ ̿̿’̿’\̵͇̿̿\=(•̪●)=/̵͇̿̿/’̿̿ ̿ ̿ ̿ ʕ•͡-•ʔ __̴ı̴̴̡̡̡
                        ̡͌l̡̡̡ ̡͌l̡*̡̡ ̴̡ı̴̴̡ ̡̡͡|̲̲̲͡͡͡ ̲▫̲͡ ̲̲̲͡͡π̲̲͡͡ ̲̲͡▫̲̲͡͡ ̲|̡̡̡ ̡ ̴̡ı̴̡̡ ̡͌l̡̡̡̡.___
                        v^√√v^──√v^√v^──√v^√√v^── 🏃‍♂️三三☲=💨 ⋆⋆⃟⊱✪⃝⃞⃝⊰⋆⃟⋆⋆ ◤◢◣◥◤◢◣◥ ◦°˚\😃/˚°◦ ♞▀▄▀▄♝▀▄
                        🐓🐓🥚 ══► 🍳 [̲̅$̲̅(̲̅10)̲̅$̲̅] ｍ（｡≧ _ ≦｡）ｍ Ꮹꄲꄲძ ℳꄲᖇᘉɪ̊ᘉ꒸.͙✼̥୭⁺ ◙▒◙♫♩♬ (ó﹏ò｡) 🌷 ꒐꓄'ꇙ ꇙꉣꋪ꒐ꋊꍌ 🌷
                    </mat-tab>
                </mat-tab-group>
            </div>


        </div>

    </div>


    <!-- DELETE BOX -->
    <div *ngIf="infoMessage && !isMobileInfo" style="width: 400px; padding: 2rem;">
      
        <div *ngIf="!isDeleting" class="mat-h2" style="text-align: center;">Are you sure you want to delete? </div>
        <!-- <div *ngIf="!isDeleting" class="mat-h4">This post will be removed permanently.</div> -->
        <div *ngIf="!isDeleting" class="mat-h1" style="text-align: center;color: #004684;">{{concept}}</div>
        <hr>

        <div class="row" style="text-align: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
       ">

            <div fxFlex fxLayoutAlign="end end" style="display: flex;flex-direction: row; gap: 1rem; align-items: flex-end;">
                <div fxFlex>

                    <button *ngIf="!isDeleting" (click)="buttonOption('cancel')" class="refreshbutton" mat-mini-fab
                        style="color: white;background-color: grey;padding: 3px;outline: none;" color="success"
                        aria-label="icon">
                        <mat-icon
                            style=" display: flex;flex-direction: column;    justify-content: center;">cancel</mat-icon>
                    </button><br>
                    <div style="color: #000;">cancel</div>
                </div>

                <div fxFlex>

                    <button *ngIf="!isDeleting && isDeletingName" (click)="buttonOption('delete')" class="refreshbutton"
                        mat-mini-fab style="color: white;background-color: red;padding: 3px;outline: none;"
                        color="success" aria-label="icon">
                        <mat-icon
                            style=" display: flex;flex-direction: column;    justify-content: center;">check</mat-icon>
                    </button><br>

                    <button *ngIf="!isDeleting && !isDeletingName" (click)="buttonOption('deleteName')"
                        class="refreshbutton" mat-mini-fab
                        style="color: white;background-color: red;padding: 4px;outline: none;" color="success"
                        aria-label="icon">
                        <mat-icon>delete</mat-icon>
                    </button>
                    <div style="color: #000;">delete</div>
                </div>
            </div>
        </div>


    </div>
    <!-- MOBILE INFO PICTURE DISPLAY -->
    <div class="cont" *ngIf="isMobileInfo">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-6 col-lg-3 text-center mb-3">
              <div>
                <p class="instruction-text">1. Access www.mynamepage.com/{{projectName}}</p>
              </div>
              <div>
                <img class="instruction-style img-fluid" src="assets/img/bsr/login_page.JPG" alt="Login">
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 text-center mb-3">
              <div>
                <p class="instruction-text">2. Enter name suggestions</p>
              </div>
              <div>
                <img class="instruction-style img-fluid" src="assets/img/bsr/page_creation.JPG" alt="Candidates">
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 text-center mb-3">
              <div>
                <p class="instruction-text">3. Edit name suggestions (optional)</p>
              </div>
              <div>
                <img class="instruction-style img-fluid" src="assets/img/bsr/editing_name.JPG" alt="Edit, Save and Delete">
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 text-center mb-3">
              <div>
                <p class="instruction-text">4. Complete</p>
              </div>
              <div>
                <img class="instruction-style img-fluid" src="assets/img/bsr/thank_you_page.JPG" alt="Logout">
              </div>
            </div>
          </div>
        </div>
        <mat-icon (click)="buttonOption('')" style="color: #004684; position: absolute; bottom: 10px; right: 10px; font-size: 24px; cursor: pointer;">disabled_by_default</mat-icon>
      </div>
      
      <style>
        .instruction-style {
          max-width: 100%;
          height: auto;
        }
      
        .instruction-text {
          font-size: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          transition: font-size 0.3s ease;
        }
      
        .cont {
          overflow-y: auto;
          max-height: 90vh; 
        }
      
        @media (min-width: 1441px) {
          .instruction-text {
            font-size: 14px;
          }
        }
      
        @media (min-width: 1281px) and (max-width: 1440px) {
          .instruction-text {
            font-size: 12px;
          }
        }
      
        @media (min-width: 1025px) and (max-width: 1280px) {
          .instruction-text {
            font-size: 10px;
          }
        }
      
        @media (max-width: 1024px) {
          .instruction-text {
            font-size: 16px; 
          }
        }
      
        @media (max-width: 768px) {
          .instruction-style {
            max-width: 80%;
            height: auto;
          }
          .instruction-text {
            font-size: 16px; 
          }
          .row {
            display: flex;
            flex-wrap: wrap;
          }
          .col-12 {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      
        @media (min-width: 769px) and (max-width: 992px) {
          .col-md-6 {
            flex: 0 0 50%;
            max-width: 50%;
          }
          .instruction-style {
            max-width: 90%;
            height: auto;
          }
          .instruction-text {
            font-size: 16px; 
          }
        }
      
        @media (min-width: 993px) and (max-width: 1024px) {
          .col-lg-3 {
            flex: 0 0 25%;
            max-width: 25%;
          }
          .instruction-style {
            max-width: 100%;
            height: auto;
          }
          .instruction-text {
            font-size: 16px; /
          }
        }
      
        .mb-3 {
          margin-bottom: 1rem !important;
        }
      </style>
      
      
      

    <!-- EDITOR CARD -->
    <div *ngIf="!infoMessage && !isMobileInfo && !isQRcode">
        <form name="loginForm" [formGroup]="loginForm" novalidate>
            <div class="editPostit" style="display: flex;flex-direction: column;">

                <div class="postItTitle" style="    display: flex;
                flex-direction: column;
                border-radius: 8px;
                border: 1px solid #004684;
                height: 90px;
                font-size: 30px;
                width: 99%">

                    <!-- CONCEPT TITLE -->
                    <div style="display: flex; flex-direction: row; color: #004684; margin: 10px;">
                        <mat-icon style="padding-top: 8px;">assignment</mat-icon>
                        <h2 class="text-bold " style="padding-left: 5px; z-index: 999;">CONCEPT TITLE</h2>
                    </div>

                    <input style="
                        padding-left: 0.5rem;
                        position: relative;
                        top: -27px;
                        width: 824px;
                        left: 5px;
                        border: none;" matInput type="Concept" formControlName="name">


                </div>

                <div class="secondRow" style="display: flex;flex-direction: row;">

                    <div class="ckeditor" style="border-radius: 8px; margin: 10px 0;
                    border: 1px solid #004684; color: #004684;  max-width: 650px;  min-width: 350px;">
                        <h2 class="text-bold " style="margin: 5px;">Key Words and Concepts</h2>

                        <mat-icon class="synonymsIcon" matTooltip="access thesaurus"
                            matTooltipPosition="above" (click)="getSynonyms(model.editorData)">layers</mat-icon>
                            <quill-editor #editor [ngModelOptions]="{standalone: true}" class="quill-editor" [(ngModel)]="model.editorData"
                            [styles]="{ height: '400px' }" [modules]="editorConfig">
              </quill-editor>
                    </div>

                    <div class="newnames" style="display: flex;flex-direction: column;border-radius: 8px;width: 30%;
                    border: 1px solid #004684; padding-left: 10px; margin: 10px;">
                        <h2 class="text-bold " style="color: #004684;">New Names</h2>
                        <textarea style="width: 94%;" matInput cdkTextareaAutosize cdkAutosizeMinRows="23"
                            formControlName="suma" appearance="outline" [value]="newNamesPerConcept"
                            [(ngModel)]="newNamesPerConcept" formControlName="suma"></textarea>

                    </div>

                </div>

                <div style="    display: flex;
                flex-direction: row;
                justify-content: center;
                position: absolute;
                height: 0px;
                right: 13px;
                bottom: 73px;">
                    <button (click)="buttonOption('savePost')" class="post-it-done-button" mat-fab color="success"
                        aria-label="icon">
                        <mat-icon style="line-height: 26px;">check</mat-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>


    <!-- OLD POST IT -->
    <!-- <div *ngIf="!infoMessage && !isMobileInfo && !isQRcode" class="">
    <form name="loginForm" [formGroup]="loginForm" novalidate class="">
        <div class="container" fxLayout="column" fxLayoutAlign="center center">
            <div class="row ">
                <div class="col">
                    <div class="title-card">

                        <div class="p-16" style="color: #004684;padding: 10px;" fxLayout="row"
                            fxLayoutAlign="start center">
                            <mat-icon style="margin-right: 10px;">assignment</mat-icon>
                            <h2 class="text-bold ">CONCEPT TITLE</h2>
                        </div>

                        <div fxLayoutAlign="center center">
                            <mat-form-field *ngIf="!isDeleting" style="width: 954px;
                            position: relative;
                            top: -20px;
                            font-size: 31px;">
                              
                                <input matInput type="Concept" formControlName="name">
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <div class="ckeditor-card">

                        <div class="p-16" style="color: #004684;padding: 10px;" fxLayout="row"
                            fxLayoutAlign="start center">

                            <h2 class="text-bold ">Key Words and Concepts</h2>

                            <mat-icon class="synonymsIcon" matTooltip="copy to clip board before search"
                                matTooltipPosition="above" (click)="getSynonyms()">layers</mat-icon>
                        </div>

                       
                        <ckeditor id="txtTexto" class="ckeditorcev" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="model.editorData" [data]="dataEditor" [config]="ckconfig"></ckeditor>
                    </div>
                </div>
                <div class="col">
                    <div class="new-names-card " style="overflow: hidden;">

                        <div class="p-16" style="color: #004684;padding: 5px;" fxLayout="row"
                            fxLayoutAlign="start center">
                            <h2 class="text-bold ">New Names</h2>
                        </div>

                       
                        <div class="p-16" style="padding: 10px;height: 780px;" fxLayout="row"
                            fxLayoutAlign="start center">
                            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="23" formControlName="suma"
                                cdkAutosizeMaxRows="23" style="
                                                        height: 460px;
                                                        width: 248px;
                                                        position: absolute;
                                                        font-size: 30px;
                                                        line-height: 28px;
                                                        top: 40px;" appearance="outline"
                                formControlName="suma"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <button (click)="buttonOption('savePost')" class="post-it-done-button" mat-fab color="success"
        aria-label="icon">
        <mat-icon>check</mat-icon>
    </button>
</div> -->


    <!-- QR CODE-->
    <div *ngIf="isQRcode" class="qr_code">
        <p style="font-family: sans-serif;font-size: 28px;margin: 0px;">scan me</p>
 <app-qr-code-popup-component style="width: 60%;" (closePopup)="onQrCodeClose()" 
                [url]="myAngularxQrCode"></app-qr-code-popup-component>

        <p><a style="color: black;" target="_blank">{{myAngularxQrCode}}</a></p>
    </div>

    <div *ngIf="isSynonymBox" class="synonyms-box mat-elevation-z8" cdkDrag>
        <app-synonyms (selectedSynonyms)="addSynonymsToEditor($event)"> </app-synonyms>
    </div>