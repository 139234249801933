import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl } from '@angular/forms';
import { CdkDragDrop, moveItemInArray, CdkDropListGroup, transferArrayItem } from '@angular/cdk/drag-drop';
import { BsrService } from './bsr.service';
// import { HotkeysService, Hotkey } from 'angular2-hotkeys';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
//import { BsrService } from './services/bsr.service';


@Component({
  selector: 'app-bsr',
  templateUrl: './bsr.component.html',
  styleUrls: ['./bsr.component.scss']
})
export class BsrComponent implements OnInit {

  @ViewChild('slider') slider;
  postItListTheme = 'post-it-list-theme'
  searchBoxLeftProperty = '611px;'
  font_size = '30';
  font_size_text = this.font_size + 'px';
  diplayFontSizeSlider = false;
  loginForm: UntypedFormGroup;
  isMouseOver: boolean = false;
  sliderVal = 51;
  totalNumberOfnames = 51;
  slideCss = 'none';
  // projectId = 'rg2327';
  projectId = 'te2687';
  projectName = 'te2687';
  createPostIt = false;
  isDeleteButon = false;
  isSearching = false;
  search: any;
  overview = false;
  isNSR = false;
  isScreenButton = true;
  isScreeningNames = false;
  slideBackground = 'url(http://www.bipresents.com/';
  baseBackgroundUrl = 'url(http://www.bipresents.com/';
  myControl = new UntypedFormControl();
  options: string[] = ['One', 'Two', 'Three'];
  totalNumberOfSlides: any;
  pageCounter = '';
  currentPageNumber = 0;
  appSlidesData: any;
  mainMenu: boolean = true;
  conceptData: any = [];
  newPost: Object;
  newName: any;
  conceptid: any;
  deletePost: Object;
  nameCandidates: any = [];
  nameBox = true;
  nameBoxB = true;
  myMaxWith = '1080px';
  myMaxWith2: any;
  myMaxRWith = '900px';
  myMaxRightWith = '8px';
  showSlider: boolean = false;
  postItPresentationIndex: number;
  appSearchSlidesData: any = [];
  slideBackground2: string;
  nameIndexCounter = 0;
  isCommentBox: boolean = false;
  commentBoxText = "";
  elem: any;
  isFullscreen = false;
  namesBoxIndexValue = 30;
  namesBoxIndex = 1;
  wideScreen = false;
  BackgroundUrl = 'http://bipresents.com/nw2/';
  BackgroundUrlOff = 'url(http://bipresents.com/nw2/assets/images/BackGrounds/Backgrounds2019/';
  baseUrl: any;
  restUrl: any;
  open: boolean = false;
  wide = true
  showHotKeys = false
  showDialog = false
  clickBlocked = false;
  loader = false
  constructor(@Inject(DOCUMENT) private document: any,
    private cdr: ChangeDetectorRef, private _BsrService: BsrService, public dialog: MatDialog, private activatedRoute: ActivatedRoute, public _snackBar: MatSnackBar,

    // private _hotkeysService: HotkeysService,
  ) {


  }

  ngOnInit(): void {
    const storedName = localStorage.getItem('userName');

    if (!storedName) {
      this.showDialog = true
    } else {
      this.showDialog = false
    }
    this.toggleNamebox()
    this.baseUrl = this._BsrService.getBaseUrlForResources();
    window.addEventListener('keydown', this.handleKeyDown.bind(this));
    if (this.baseUrl === 'https://bitools.s3.amazonaws.com/nw-resources/') {
      this.BackgroundUrl = 'https://d3lyn5npnikbck.cloudfront.net/'
    }


    this.font_size_text = (localStorage.getItem(this.projectName + '_font_size_text')) ? localStorage.getItem(this.projectName + '_font_size_text') : '26px';
    this.font_size = (localStorage.getItem(this.projectName + '_font_size')) ? localStorage.getItem(this.projectName + '_font_size') : '26';
    this.activatedRoute.params.subscribe(params => {

      // set project ID as localstorage identifier 03/16/21
      this.projectName = params['id'];
      this._BsrService.setProjectName(this.projectName);
      localStorage.setItem(this.projectName + '_projectId', this.projectName);
      localStorage.setItem(this.projectName + '_projectName', this.projectName);
      this.projectId = this.projectName;
    });

    this.assignCopy();
    this.elem = document.documentElement;

    this.postItListTheme = localStorage.getItem(this.projectName + '_post-it-list-theme');
    this._BsrService.getSlides(this.projectId).subscribe((res: any) => {
      this.appSlidesData = res;
      // this.appSearchSlidesData = res;
      localStorage.setItem(this.projectName + '_appSlideData', JSON.stringify(res));
      this.totalNumberOfSlides = res.length;
      this.pageCounter = '1/' + (parseInt(this.totalNumberOfSlides));
      let slideRes = res[0].SlideBGFileName
      this.wide = res[0].isWide != null ? true : false
      this.slideBackground = this.slideBackground + slideRes + ')';
      this.appSlidesData.forEach(element => {
        if (element.SlideType === "NameSummary") {
          this.postItPresentationIndex = parseInt(element.$id) - 1;
        }
      });
      this.createPostIt = false;
      if (this.createPostIt) {
        this.searchBoxLeftProperty = '777px';
        this.currentPageNumber = (this.createPostIt) ? this.appSlidesData[0].SummarySlide - 1 : 0;
        this.pageCounter = (this.createPostIt) ? this.appSlidesData[0].SummarySlide + '/' + (parseInt(this.totalNumberOfSlides)) : '1/' + (parseInt(this.totalNumberOfSlides));
      } else {
        this.searchBoxLeftProperty = '611px;';
        this.currentPageNumber = 0;

      }
    })

    this._BsrService.getPost().subscribe((res: any) => {
      this.conceptData = JSON.parse(res[0].bsrData);

      if (JSON.parse(res[0].bsrData).presentationtype === 'NSR') {
        this.isNSR = true;
      }

      this.conceptData.concepts.forEach(element => {
        element.concept = element.concept.replace(/`/g, "'");
        element.html = element.html.replace(/`/g, "'");
      });
    });


    setInterval(() => {
      this._BsrService.getNameCandidates(this.projectId).subscribe((res: any) => {
        res.forEach(name => {
          name.html = name.html.replace(/\\/g, '');
        });
        this.nameCandidates = (res.length > 0) ? res : [];
      });
    }, 10000);


    this.getCommentsByIndex(0);
    this.loginForm = new FormGroup({
      rationale: new FormControl(""),
      suma: new FormControl(""),
      name: new FormControl("")
    });
    this.nameIndexCounter = (localStorage.getItem(this.projectName + '_namesIndexCounte')) ? parseInt(localStorage.getItem(this.projectName + '_namesIndexCounte')) : 0;


    this.onInputChange(parseInt(localStorage.getItem(this.projectName + '_namesBoxIndex')));
    this.currentPageNumber = 1;
  }


  promptForName(name) {
    if (name) {
      localStorage.setItem('userName', name);
      this.showDialog = false
      return true
    } else {
      this.showDialog = true
      return false
    }
  }


  getCommentsByIndex(index) {
    this._BsrService.getComments(index).subscribe((arg: any) => {
      if (arg.length > 0) {
        this.commentBoxText = JSON.parse(arg)[0].Comments;
      } else {
        this.commentBoxText = '';
      }
    });
  }
  wideScreenView() {
    this.wide = !this.wide
  }

  boxWidth = 155;
  // calculated based on dynamic row width
  columnSize: number;

  getItemsTable(rowLayout: Element): number[][] {
    // calculate column size per row
    const { width } = rowLayout.getBoundingClientRect();
    const columnSize = Math.round(width / this.boxWidth);
    // view has been resized? => update table with new column size
    if (columnSize != this.columnSize) {
      this.columnSize = columnSize;
      this.initTable();
    }
    this.conceptData.concepts.forEach(element => {
      element.concept = element.concept.replace(/`/g, "'");
      element.html = element.html.replace(/`/g, "'");
    });
    return this.conceptData.concepts;
  }

  initTable() {
    // create table rows based on input list
    // example: [1,2,3,4,5,6] => [ [1,2,3], [4,5,6] ]
    this.conceptData.concepts = this.conceptData.concepts
      .filter((_, outerIndex) => outerIndex % this.columnSize == 0) // create outter list of rows
      .map((
        _,
        rowIndex // fill each row from...
      ) =>
        this.conceptData.concepts.slice(
          rowIndex * this.columnSize, // ... row start and
          rowIndex * this.columnSize + this.columnSize // ...row end
        )
      );
  }

  reorderDroppedItem(event: CdkDragDrop<number[]>) {
    // same row/container? => move item in same row
    if (event.previousContainer === event.container) {

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      // different rows? => transfer item from one to another list
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    // update items after drop: flatten matrix into list
    // example: [ [1,2,3], [4,5,6] ] => [1,2,3,4,5,6]
    this.conceptData.concepts = this.conceptData.concepts.reduce(
      (previous, current) => previous.concat(current),
      []
    );

    // re-initialize table - makes sure each row has same numbers of entries
    // example: [ [1,2], [3,4,5,6] ] => [ [1,2,3], [4,5,6] ]
    this.initTable();
  }

  orderArray(orderArray) {


    const orderIds = orderArray.concepts.map((element) => element.conceptid);

    const uniqueOrderIds = [...new Set(orderIds)];

    //if (this.clickBlocked) return;



    this._BsrService.postItOrder(this.projectId, uniqueOrderIds).subscribe(arg => {
      this.clickBlocked = true;
      this._BsrService.getPost().subscribe((res: any) => {
        this.conceptData = JSON.parse(res[0].bsrData);
        this.cdr.detectChanges();
        if (this.conceptData.presentationtype === 'NSR') {
          this.isNSR = true;
          this.clickBlocked = false;
        }



      }, (error) => {
        console.error('Error al obtener los ítems:', error);
        this.clickBlocked = false;
      });
    }, (error) => {
      console.error('Error al subir el cambio:', error);
      this.clickBlocked = false;
    });
  }
  trackByFn(index, item) {
    return item.conceptid;
  }


  dropped(event: CdkDragDrop<any>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      return
    }
  }


  // TOOLBAR MENU ACTIONS
  moveForward() {
    this.searchBoxLeftProperty = '611px;';
    this.appSearchSlidesData = [];
    this.isCommentBox = false;
    this.createPostIt = false;
    if (this.totalNumberOfSlides > this.currentPageNumber + 1) {
      this.currentPageNumber = 1 + this.currentPageNumber;
      this.slideBackground = this.baseBackgroundUrl + this.appSlidesData[this.currentPageNumber].SlideBGFileName + ')';
      if (this.postItPresentationIndex === this.currentPageNumber) {
        this.createPostIt = true;
        this.searchBoxLeftProperty = '777px';
      }
      this.pageCounter = this.currentPageNumber + 1 + '/' + this.totalNumberOfSlides;
      if (this.currentPageNumber + 1 == this.appSlidesData[this.currentPageNumber].SummarySlide) {
        this.bsr()
      } else {
        this.createPostIt = false;

      }
    } else {
      this.goToSlide(this.currentPageNumber);
    }
  }


  moveBackward() {
    this.searchBoxLeftProperty = '611px;';
    this.appSearchSlidesData = [];
    this.isCommentBox = false;
    this.createPostIt = false
    if (this.currentPageNumber >= 1) {
      this.currentPageNumber = this.currentPageNumber - 1;
      this.pageCounter = this.currentPageNumber + 1 + '/' + this.totalNumberOfSlides;
      this.slideBackground = this.baseBackgroundUrl + this.appSlidesData[this.currentPageNumber].SlideBGFileName + ')';
      if (this.postItPresentationIndex === this.currentPageNumber) {
        this.createPostIt = true;
        this.searchBoxLeftProperty = '777px';
      }
      if (this.currentPageNumber + 1 == this.appSlidesData[this.currentPageNumber].SummarySlide) {

        this.bsr()
      } else {
        this.createPostIt = false;
      }
    }
  }
  handleKeyDown(event: KeyboardEvent): void {
    const focusedElement = document.activeElement as HTMLElement;
    if (focusedElement && focusedElement.id === 'mat-input-0') {
      return;
    }
    if (!this.open && !this.isCommentBox) {
      if (event.key === 'ArrowUp') {
        this.mainMenu = true
      } else if (event.key === 'ArrowDown') {
        this.mainMenu = false
      }

      else if (event.key === 'ArrowRight') {
        this.moveForward();
      } else if (event.key === 'ArrowLeft') {
        this.moveBackward();
      } else if (event.shiftKey && event.altKey && (event.key === 'o' || event.key === 'O')) {
        this.overview = !this.overview;
      } else if ((event.key === 'b' || event.key === 'B') && event.ctrlKey) {

        if (!this.createPostIt) {
          this.bsr()
        } else {
          this.home()
        }

      } else if (event.key === 'Escape') {
        this.showHotKeys = false
      }
    }
  }
  changeUser() {
    this.showDialog = true
  }
  submitNewName() {

    this.loginForm.value.name.split(',').forEach(element => {
      const userName = localStorage.getItem('userName')
      this._BsrService.sendNewName(element, userName, this.isNSR).subscribe(arg => {
      });
    });
    setTimeout(() => {
      this._BsrService.getNameCandidates(this.projectId).subscribe((res: any) => {
        this.nameCandidates = res;
      });
    }, 300);
  }

  newBlankPostIt() {
    let newConcepData = {
      projectId: this.projectId,
      conceptid: '0',
      concept: 'Concept',
      conceptorder: '0',
      attributes: [],
      names: []
    }
    this._BsrService.newPost(JSON.stringify(newConcepData)).subscribe(arg => {
      this._BsrService.getPost().subscribe((res: any) => {
        this.conceptData = JSON.parse(res[0].bsrData);
        this.conceptData.concepts.forEach(element => {
          element.concept = element.concept.replace(/`/g, "'");
          element.html = element.html.replace(/`/g, "'");
        });
      });
    });

  }

  sideMenu() {
    this.overview = !this.overview;
  }

  home() {
    this.searchBoxLeftProperty = '611px;';
    this.pageCounter = '1/' + this.totalNumberOfSlides;
    this.slideBackground = this.baseBackgroundUrl + this.appSlidesData[0].SlideBGFileName + ')';
    this.createPostIt = false;
    this.currentPageNumber = 0;
    localStorage.setItem(this.projectName + '_namesIndexCounte', '0');
    localStorage.setItem(this.projectName + '_createPostIt', 'false');
  }

  bsr() {
    // reset search data
    this.appSearchSlidesData = [];
    this.mainMenu = false;
    this.createPostIt = !this.createPostIt;
    if (this.createPostIt) {
      this.searchBoxLeftProperty = '777px';
    } else {
      this.searchBoxLeftProperty = '611px;';
    }

    localStorage.setItem(this.projectName + '_createPostIt', this.createPostIt.toString());
    this.nameIndexCounter = parseInt(localStorage.getItem(this.projectName + '_namesIndexCounte'));
    this.onInputChange(parseInt(localStorage.getItem(this.projectName + '_namesBoxIndex')));

  }

  displayCommentBox() {
    this.isCommentBox = !this.isCommentBox;
    (this.isCommentBox) ? this.getCommentsByIndex(this.currentPageNumber) : null;
  }

  comment() {

    if (this.isCommentBox) {

      let comment = this.projectId + "','" + this.currentPageNumber + "',N'" + this.commentBoxText + "'";

      this._BsrService.sendComment(comment).subscribe((res: any) => {
        let newConcepData = {
          projectId: this.projectId,
          conceptid: '-1',
          concept: 'Concept',
          conceptorder: '0',
          attributes: [],
          names: []
        }

        this._BsrService.newPost(JSON.stringify(newConcepData)).subscribe((arg: any) => {
          this.conceptData = JSON.parse(JSON.parse(arg)[0].bsrData);
          let summayConceptId = '';
          this.conceptData.concepts.forEach(element => {
            if (element.concept === "SUMMARY" || element.concept === "summary") {
              summayConceptId = element.conceptid
            }
          });


          // SUMMIRIZE COMMENTS INTO A POST IT
          let comments = '';
          res = JSON.parse(res)
          res.forEach(element => {
            comments += "<p>" + element.Comments + "<p>"
          });


          let newConcepData2 = {
            projectId: this.projectId,
            concept: 'SUMMARY',
            conceptid: '"' + summayConceptId + '"',
            attributesArray: '',
            namesArray: '',
            conceptHtml: comments
          }
          const userName = localStorage.getItem('userName')
          this._BsrService.updatePost(JSON.stringify(newConcepData2), userName)
            .subscribe(arg => {

              this._BsrService.getPost().subscribe((res: any) => {
                this.conceptData = JSON.parse(res[0].bsrData);
                // this.conceptData.concepts[0].html = this.commentBoxText;
                this.conceptData.concepts.forEach(element => {
                  element.concept = element.concept.replace(/`/g, "'");
                  element.html = element.html.replace(/`/g, "'");
                });
                this.isCommentBox = false;
                this.commentBoxText = '';
              });

            });

        });

      });
    }
  }

  displayHelp(display: boolean) {
    // (display) ? this._hotkeysService.cheatSheetToggle.next() : this._hotkeysService.cheatSheetToggle.next(display);
    // this._hotkeysService.cheatSheetToggle.next(true)
  }

  goToSlide(i) {
    this.overview = false;
    this.currentPageNumber = i;


    if (this.postItPresentationIndex == this.currentPageNumber) {
      this.createPostIt = true;

    } else {
      if (i) {
        let slideBG = this.appSlidesData[i].SlideBGFileName;
        this.slideBackground = this.baseBackgroundUrl + slideBG + ')';
      }

      this.createPostIt = false;
    }


    this.pageCounter = i + 1 + '/' + this.totalNumberOfSlides;
  }

  goToSlideFromSearch(i: string) {
    const ii = parseInt(i) - 1;
    this.slideBackground = this.baseBackgroundUrl + this.appSlidesData[ii].SlideBGFileName + ')';
    this.createPostIt = false;
    this.pageCounter = ii + 1 + '/' + this.totalNumberOfSlides;
  }


  openDialog(item, nameid) {
    this.open = true;
    this.clickBlocked = true;
    const dialogRef = this.dialog.open(editPost, {
      // width: ((nameid === 'edit')?'80%':'100%'),
      // height: ((nameid === 'edit') ? '777px' : '200px'),
      data: {
        name: item,
        nameId: nameid
      }
    });
    this.conceptid = item.conceptid;
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel' ) {
        this.loader = true
      }
      const editPostInstance = dialogRef.componentInstance;
      if (result !== 'deleteName' && result !== 'cancel' ) {
        editPostInstance.buttonOption('savePost');
      }
      setTimeout(() => {

        this.open = false;
        this.conceptData.concepts.forEach(element => {
          element.concept = element.concept.replace(/`/g, "'");
          element.html = element.html.replace(/`/g, "'");
        });
        if (result === 'delete') {
          if (this.conceptid === '-1') {
            this.conceptid = "'" + -1 + '"';
          }
          this._BsrService.deletePost(this.conceptid).subscribe(arg => {
            this._BsrService.getPost().subscribe(res => {
              this.conceptData = JSON.parse(res[0].bsrData);
              this.conceptData.concepts.forEach(element => {
                element.concept = element.concept.replace(/`/g, "'");
                element.html = element.html.replace(/`/g, "'");
              });
              this.loader = false

            },
              error => {
                this.loader = false

              });
          });
        } else if (result === 'deleteName' && result !== 'cancel' ) {
          this._BsrService.getNameCandidates(this.projectId).subscribe(res => {
            this.nameCandidates = res;
          });
        }
   
        if (result !== 'cancel' ) {

          this._BsrService.getPost().subscribe(
            res => {
              this.conceptData = JSON.parse(res[0].bsrData);
              this.conceptData.concepts.forEach(element => {
                element.concept = element.concept.replace(/`/g, "'");
                element.html = element.html.replace(/`/g, "'");
              });
              if (JSON.parse(res[0].bsrData).presentationtype === 'NSR') {
                this.isNSR = true;
              }
              this.loader = false
              this._snackBar.open('Data was saved', 'OK', {
                duration: 5000,
                horizontalPosition: 'right',
                verticalPosition: 'top'
              });
            },
            error => {
              this.loader = false
              this._snackBar.open('There was an error saving data', 'OK', {
                duration: 5000,
                horizontalPosition: 'right',
                verticalPosition: 'top'
              });

            });
        }
        if (result !== 'deleteName' && result !== 'cancel' ) {
          editPostInstance.buttonOption('savePost');
        }
        this._BsrService.getNameCandidates(this.projectId).subscribe({

          next: (response: any) => {
            this.nameCandidates = response
          },
          error: (error) => {
            console.log("cant get namecadidates")
          },

        });
        this.clickBlocked = false;
      }, 500);
    });

  }

  assignCopy() {
    // this.appSearchSlidesData = Object.assign([], this.appSlidesData);
  }

  searchTerm(searchValue: string): void {

    if (!searchValue || searchValue === '') {
      this.appSearchSlidesData = [];
    } else {

      this.appSearchSlidesData = Object.assign([], this.appSlidesData).filter(
        item => item.SlideDescription.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
      )
    } // when nothing has typed
  }

  theme(): void {
    if (this.postItListTheme == 'post-it-list-theme') {
      this.postItListTheme = 'post-it-list'
    } else {
      this.postItListTheme = 'post-it-list-theme'
    }
    localStorage.setItem(this.projectName + '_post-it-list-theme', this.postItListTheme);
    let audio = new Audio();
    audio.src = "assets/sound/tap.wav";
    audio.volume = 0.02;
    audio.load();
    audio.play();
  }

  toggleNamebox() {
    // this.namesBoxIndex = parseInt(localStorage.getItem('namesBoxIndex'));
    if (this.namesBoxIndex === 0) {
      this.namesBoxIndex++;
      this.onInputChange(52);
    } else if (this.namesBoxIndex === 1) {
      this.namesBoxIndex++;
      this.onInputChange(30);
    } else {
      this.namesBoxIndex = 1;
      this.onInputChange(52);
    }
  }



  onInputChange(value: number) {
    // console.log("This is emitted as the thumb slides");
    // console.log(value);
    if (value > 51) {
      this.myMaxWith = '935px';
      this.myMaxRWith = '300px';
      this.myMaxRightWith = '-1px';
      this.nameBox = false;
      this.nameBoxB = false;
      this.isScreenButton = false;
    } else if (value <= 51 && value > 25) {
      this.myMaxWith = '925px';
      this.myMaxRWith = '340px';
      this.myMaxRightWith = '8px';
      this.nameBox = true;
      this.nameBoxB = true;
      this.isScreenButton = true;
    } else if (value <= 25) {
      this.myMaxWith = '335px';
      this.myMaxRWith = '636px';
      this.myMaxRightWith = '352px';
      this.nameBox = true;
      this.nameBoxB = false;
      this.isScreenButton = true;
    }
    localStorage.setItem(this.projectName + '_namesBoxIndex', this.namesBoxIndexValue.toString());
  }


  screenNames() {
    this.isScreeningNames = !this.isScreeningNames;
  }

  toogleMenus() {
    this.mainMenu = !this.mainMenu;
  }

  openFullscreen() {
    this.elem = document.documentElement;
    this.isFullscreen = !this.isFullscreen;
    if (this.isFullscreen) {
      if (this.elem.requestFullscreen) {
        this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
        /* Firefox */
        this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
        /* IE/Edge */
        this.elem.msRequestFullscreen();
      }
    }
    else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      }
      else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }

  }


  setFontSize() {
    // console.log(this.font_size);
    this.font_size_text = this.font_size + 'px !important';

    localStorage.setItem(this.projectName + '_font_size_text', this.font_size_text);
    localStorage.setItem(this.projectName + '_font_size', this.font_size);
  }

}



import { MatSliderChange } from '@angular/material/slider';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, forkJoin, map } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { error } from 'console';
// import { ThrowStmt } from '@angular/compiler/src/output/output_ast';
// import { DragulaService } from 'ng2-dragula';

// CKEDITOR WYSIWYG // **************************************************************************************************

export interface DialogData {
  nameId: any;
  name: any;
}


export interface PeriodicElement {
  synonyms: string;
  position: number;
  weight: number;
  symbol: string;
}




// POST EDITOR COMPONENT

@Component({
  selector: 'editPost',
  templateUrl: 'editPost-it.html',
  styleUrls: ['./bsr.component.scss']
})
export class editPost {

  ckconfig: any;
  synonyms: any;
  loginForm: UntypedFormGroup;
  isDeleting = false;
  isDeletingName = false;
  dataEditor = '<p>Hello, world!</p>';
  infoMessage = true;
  popupwindowData: { form: UntypedFormGroup; oldValue: string; };
  title: string;
  editName: string;
  concept: any;
  projectId = '';
  // projectId = 'rg2327';
  public model = {
    editorData: '',
    namesData: ''
  };
  editorConfig: any
  newNamesPerConcept = ''
  conceptid = ''

  isMobileInfo: boolean;
  allComplete: boolean;
  isSynonymBox = false;
  isEmojiTime: boolean = false;


  displayedColumns: string[] = ['name', 'weight'];
  synonymWord: string = ' Copy name to clipboard ';
  dataSource = new BehaviorSubject<any[]>([]);
  public myAngularxQrCode: string = null;
  isQRcode: boolean;
  nameid: any = '';
  showQrCode = false
  projectName = ''

  closeQrCodePopup() {
    this.showQrCode = !this.showQrCode;
  }
  onQrCodeClose() {
    this.showQrCode = false;
  }
  openCorpwebsite() {
    window.open(this.myAngularxQrCode, '_blank');
  }
  constructor(
    public dialogRef: MatDialogRef<editPost>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private _BsrService: BsrService, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef, public _snackBar: MatSnackBar) {
    this.editName = this.data.nameId;
    this.dataEditor = this.data.name.html;
    this.model.editorData = this.data.name.html;
    this.title = this.data.name.Name;
    this.editorConfig = {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
      ]
    };
    if (this.data.name.names) {
      this.data.name.names.forEach(newName => {
        this.newNamesPerConcept += newName.name + '\n';
        this.nameid += newName.nameid + '\n';

      });
    }

    this.conceptid = this.data.name.conceptid;

    this.projectId = '';
    this.projectName = this._BsrService.getProjectName()    // assign a value
    // this.myAngularxQrCode = 'http://www.bipresents.com/'+ this.projectId;
    this.myAngularxQrCode = ' www.mynamepage.com/' + localStorage.getItem(this._BsrService.getProjectName() + '_projectName');
    if (this.data.name.Name) {
      this.concept = this.data.name.Name;
    } else {
      this.concept = this.data.name.concept;
      this.isDeletingName = true;
    }
    if (this.data.nameId === 'delete') {
      this.infoMessage = true;
      this.isDeleting = false;
      // this.isDeletingName = false;
    } else if (this.data.nameId === 'edit') {
      this.infoMessage = false;
      this.isDeleting = false;
    }
    else {
      this.infoMessage = true;
      this.isDeleting = false;
    }
    if (this.data.name === 'mobileInfo') {
      this.infoMessage = false;
      this.isDeleting = false;
      this.isMobileInfo = true;
    }
    if (this.data.name === 'qr_code') {
      this.infoMessage = false;
      this.isDeleting = false;
      this.isMobileInfo = false;
      this.isQRcode = true;
    }
    this.ckconfig = {
      allowedContent: false,
      width: '99.6%',
      contentsCss: ["body {font-size: 24px;}"],
      height: 380,
      forcePasteAsPlainText: true,
      toolbarLocation: 'top',
      toolbarGroups: [
        { name: 'clipboard', groups: ['clipboard', ''] },
        { name: 'insert' },
        { name: 'forms' },
        { name: 'tools' },
        { name: 'document', groups: ['mode', 'document', 'doctools'] },
        { name: 'others' },
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'colors' },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
        { name: 'styles' },
        { name: 'links' },
        { name: 'about' }
      ],
      addPlugins: 'simplebox,tabletools',
      removePlugins: 'horizontalrule,specialchar,about,others',
      removeButtons: 'Smiley,tableselection,Image,Superscript,Subscript,Save,NewPage,Preview,Print,Templates,Replace,SelectAll,Form,Checkbox,Radio,TextField,Textarea,Find,Select,Button,ImageButton,HiddenField,CopyFormatting,CreateDiv,BidiLtr,BidiRtl,Language,Flash,PageBreak,Iframe,ShowBlocks,Cut,Copy,Paste,Table,Format,Source,Maximize,Styles,Anchor,SpecialChar,PasteFromWord,PasteText,Scayt,RemoveFormat,Indent,Outdent,Blockquote'

    }
    this.loginForm = new FormGroup({
      rationale: new FormControl(""),
      suma: new FormControl(""),
      name: new FormControl(this.concept)
    });
  }



  buttonOption(option) {
    if (option === 'savePost' && this.infoMessage){
      this.dialogRef.close('cancel');
    }
    if (option === 'delete') {
      this.isDeleting = false;
      this.dialogRef.close('delete');
    } else if (option === 'savePost' && !this.infoMessage) {
      this.isDeleting = false;
      this.projectId = localStorage.getItem(this._BsrService.getProjectName() + '_projectId');

      const editorData = this.model.editorData && this.model.editorData.trim() ? this.model.editorData : '';
      //console.log(this.data)
      const newConcepData = {
        projectId: this.projectId,
        concept: this.loginForm.value.name.replace(/'/g, "`"),
        conceptid: JSON.stringify(this.data.name.conceptid),
        attributesArray: this.data.name.attributes,
        namesArray: this.model.namesData ? this.model.namesData.split("\n").filter(name => name.trim() !== '') : [''],
        conceptHtml: editorData,
      };
      //console.log(newConcepData)

      const userName = localStorage.getItem('userName')
      this._BsrService.updatePost(JSON.stringify(newConcepData), userName).subscribe({
        next: (response: any) => { this.dialogRef.close('savePost'); },
        error: (error) => {

          this._snackBar.open('cant save post', 'Error', {
            duration: 5000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ["error-snackbar"]
          });
        }
      });
    } else if (option === 'deleteName') {
      this.isDeleting = false;
      this._BsrService.deleteName(this.data.name.NameId).subscribe(arg => { });
      this.dialogRef.close('deleteName');
    } else {
      this.dialogRef.close('cancel');
    }


    if (!this.nameid) {
      this.nameid = ''
    }
    if (this.newNamesPerConcept ) {
      const newNames = this.newNamesPerConcept.split('\n').filter(name => name.trim() !== '');
      const nameIds = this.nameid.split('\n');
      newNames.forEach((element, index) => {
        const tempArray = this.nameid.split('\n');
        const nameId = tempArray[index] ? tempArray[index] : '0';
        if (nameId) {
          const userName = localStorage.getItem('userName')
          this._BsrService.sendNewName(element, userName, false, this.conceptid, nameId).subscribe({
            next: (response: any) => { console.log("newName saved") },
            error: (error) => { console.log("cant save new name") },
          });
        }
      });

    }
  }
  onNoClick(): void {
    this.popupwindowData = {
      form: this.loginForm,
      oldValue: this.data.name
    }
    this.dialogRef.close(this.popupwindowData);
  }
  async getSynonyms(synonyms: any) {
    const regex = /<p>(.*?)<\/p>/g;
    const result = [];
    let match;

    while ((match = regex.exec(synonyms)) !== null) {

      const tempElement = document.createElement('div');
      tempElement.innerHTML = match[1];
      result.push(tempElement.textContent || tempElement.innerText || '');
    }

    this.synonymWord = result.join(', ');
    this.isSynonymBox = true;

    const requests = result.map(element =>
      this._BsrService.getSinonyms(element).pipe(
        map((res: any) => {
          const parsedRes = JSON.parse(res);
          return { word: element, synonyms: parsedRes };
        })
      )
    );

    forkJoin(requests).subscribe((results: any[]) => {
      const data: any[] = [];
      results.forEach((res, index) => {
        const word = res.word;
        const synonyms = res.synonyms.map((synonym: any) => synonym.word);
        data.push({ word, synonyms });
      });
      this.dataSource.next(data);
      this.cdr.markForCheck();
    });
  }
  setAll(evt) {
    this.model.editorData = this.model.editorData.concat('<p>' + evt + '</p>');
  }

  addSynonymsToEditor(synonyms) {
    synonyms.forEach(synonym => {
      this.model.editorData += `<p>${synonym}</p>`;
    });
    this.model.editorData = this.model.editorData.replace(/null/g, '');

    this.isSynonymBox = false
  }
  emojiToggle() {
    this.isEmojiTime = !this.isEmojiTime;
  }



}
function toTop(nameid: any) {
  throw new Error('Function not implemented.');
}

